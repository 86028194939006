header {
    width: 100%;
    height: 50px;
    max-height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  header .pagetitle {
    color: #263c15;
    font-size: 2rem;
    font-weight: 1000;
    margin-left: 1rem;
  }
  
  header > span {
    color: #263c15;
    font-size: 2rem;
    font-weight: bold;
    margin-left: 1rem;
  }
  
  header > .navi {
    display: flex;
    justify-content: right;
    align-items: center;
  }
  
  header > .navi > .buttonarea {
    display: flex;
  }
  header > .navi > .buttonarea > p {
    width: 10px;
  }
  
  header > .navi > .userinfo {
    margin-left: 20px;
    margin-right: 20px;
  }
  header > .navi > .userinfo > p {
    font-size: 1.0rem;
    font-weight: 1000;
    padding:0px;
    line-height:0.6rem;
    width:300px;
    text-align:left;
  }
  