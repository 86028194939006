.frame {
  max-width: 100%;
  padding: 10px;
}

.frame > .frame_container {
  border: 1px solid #007d50;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
}

.frame > .frame_container > .frame_header {
  margin-top: 1px;
  margin-left: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-weight: normal;
}
.frame > .frame_container > .frame_header > span {
  background-color: #fff;
  color: #007d50;
  font-size: 1em;
  font-weight: bold;
}
